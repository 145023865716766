import SingleEventSection from './SingleEventSection'
import Destination from './Destination'
import Gallery from './Gallery'

// data for japan
const japan = {
    galleries: [
        [
            '/photos/events/japan/japan_IMG_3178.jpg',
            '/photos/events/japan/japan_IMG_3182.jpg',
            '/photos/events/japan/japan_IMG_3186.jpg',
            '/photos/events/japan/japan_IMG_3196.jpg',],
        [
            '/photos/events/japan/japan_IMG_3194.jpg',
            '/photos/events/japan/japan_IMG_3188.jpg'
        ]
    ]
}

// data for japan
const vietnam = {
    galleries: [
        [
            // '/photos/events/vietnam/vietnam_IMG_3546.jpg',
            // '/photos/events/vietnam/vietnam_IMG_3547.jpg',
            '/photos/events/vietnam/vietnam_IMG_5143.jpg',
            '/photos/events/vietnam/vietnam_IMG_5144.jpg',],
        [
            // '/photos/events/vietnam/vietnam_IMG_5145.jpg',
            // '/photos/events/vietnam/vietnam_IMG_5146.jpg',
            // '/photos/events/vietnam/vietnam_IMG_5147.jpg',
            // '/photos/events/vietnam/vietnam_IMG_5148.jpg',
            '/photos/events/vietnam/vietnam_IMG_5149.jpg',
            '/photos/events/vietnam/vietnam_IMG_5150.jpg'
        ]
    ]
}


const PastEventsSection = () => {
    return (
        <div>
            <div className="border border-rose-400 \
                py-4 rounded-md mb-8">
                <h1 className="text-center text-4xl font-bold text-rose-400">
                    Past
                    <img className="inline-block rounded-md w-36 mx-2" src="/icons/plant-based_rose-400.png" alt="plant-based" />
                    Events
                </h1>
            </div>

            <SingleEventSection title="Vietnamese" dateString="Saturday, 28 September 2024">
                {vietnam.galleries.map((items, i) =>
                    <Gallery key={i} items={items} />
                )}
                <p className="leading-loose">
                    We end up in <Destination text="Vietnam" /> for our next destination.
                    Vietnamese cuisine emphasises on a cohesive balance of taste, topped with fresh herbs, spices and condiments.
                    Let us indulge in the wide array of flavours Vietnamese cuisine has to offer!
                </p>
            </SingleEventSection>

            <SingleEventSection title="Japanese" dateString="Saturday, 18 May 2024">
                {japan.galleries.map((items, i) =>
                    <Gallery key={i} items={items} />
                )}
                <div className="flex flex-wrap mb-8">
                    <img className="w-1/2 pr-1 rounded-md mb-2" src="/photos/thumbnails/壽司.jpg" />
                    <img className="w-1/2 pl-1 rounded-md mb-2" src="/photos/thumbnails/壽司2.jpg" />
                </div>
                <p className="leading-loose">
                    Arriving in <Destination text="Japan" />, we invite one
                    of our youngest chefs on deck to bring us sushi in its finest
                    forms. We hope you will be as surprised as we were when you
                    see for yourself the numerous creative ways that a seemingly simple
                    dish can be prepared.
                </p>
            </SingleEventSection>

            <SingleEventSection title="Indonesian" dateString="Saturday, 7 October 2023">
                <div className="flex flex-wrap mb-8">
                    <img className="rounded-md mb-2" src="/photos/thumbnails/nasi_kuning.jpg" />
                </div>
                <p className="leading-loose mb-8">
                    Our second stop is in <Destination text="Indonesia" />.
                    Let us experience the power of spice on plant-based Nasi Kuning, along with other Indonesian dishes.
                </p>
            </SingleEventSection>

            <SingleEventSection title="Western" dateString="Saturday, 26 August 2023">
                <div className="flex flex-wrap mb-8">
                    <img className="rounded-md mb-2" src="/photos/events/western.jpeg" />
                    <img className="rounded-md mb-2" src="/photos/thumbnails/漢堡.jpg" />
                </div>
                <p className="leading-loose mb-8">
                    We begin our journey in <Destination text="Australia" /> with something a little closer to home,
                    a Western classic — the hamburger and fries. The twist
                    being the popular duo re-imagined in its plant-based forms.
                </p>
            </SingleEventSection>
        </div>
    )
}

export default PastEventsSection
