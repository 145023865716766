import { useEffect } from 'react'
import UpcomingEventsSection from './UpcomingEventsSection'
import PastEventsSection from './PastEventsSection'

const EventsPage = () => {
    useEffect(() => {
        document.title = "The Plant-Based Project"
    })

    return (
        <div>
            { /* <UpcomingEventsSection /> */ }
            <PastEventsSection />
        </div>
    )
}

export default EventsPage
