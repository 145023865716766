import configOverrides from './config.env'

const host = window.location.origin

const nextEventDateString = 'Saturday, 28 September 2024'
const nextEventTimeString = '10:45am'
const now = new Date()
const broadcastNextEvent = now < new Date(nextEventDateString)

const config = {
    DEBUG_MODE: false,
    API_BASE_URL: `${host}/api`,
    broadcastNextEvent, // toggle this to show rsvp buttons
    nextEventDateString,
    nextEventTimeString,
    nextEventFormUrl: 'https://forms.gle/LyBNGuxD2nHfjbZv6',
    ...configOverrides
}

export default config
